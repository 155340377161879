require('./bootstrap');

/* Form SUBMIT disable button. */
/* =========================================================== */
$(document).on('submit', 'form', function() {

    let buttonHtml = $('button[type=submit]', this).html();

    $('button[type=submit]', this).attr('disabled', true).prepend('<i class="fas fa-circle-notch fa-spin mr-1"></i>');

    if($(this).hasClass('confirm')) {

        $('button[type=submit]', this).attr('disabled', false).html(buttonHtml);
        return confirm('Are you sure?');

    } //end if

    return;
});

/* Flatpickr date input field. */
/* =========================================================== */
$('.flatpickr').flatpickr({
    altInput: true,
    altFormat: 'F j, Y',
    dateFormat: 'Y-m-d'
});

/* Flatpickr clear input field. */
/* =========================================================== */
$(document).on('click', '.clear-flatpickr', function() {
    let target = $(this).data('target');

    $('#' + target).flatpickr({
        altInput: true,
        altFormat: 'F j, Y',
        dateFormat: 'Y-m-d'
    }).clear();
});

/* Toastr config options. */
/* =========================================================== */
toastr.options = {
    'positionClass': 'toast-bottom-right',
    'onclick': null,
    'showDuration': '300',
    'hideDuration': '1000',
    'timeOut': '5000',
    'extendedTimeOut': '1000',
    'showEasing': 'swing',
    'hideEasing': 'linear',
    'showMethod': 'fadeIn',
    'hideMethod': 'fadeOut'
}

/* BS4 Tooltip */
/* =========================================================== */
$(function () {
    $('body').tooltip({ selector: '[data-toggle=tooltip]' });
});
